import { PanelPlugin } from '@grafana/data';
import SimplePanel from './components/SimplePanel';
import { SimpleOptions } from './types';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions(builder => {
  return builder
    .addNumberInput({
      path: 'columns',
      name: 'Columns',
      description: 'Set the number of columns',
      defaultValue: 5,
      settings: {
        min: 1,
        max: 64
      },
      category: ['Grid'],
    })
    .addNumberInput({
      path: 'rows',
      name: 'Rows',
      description: 'Set the number of rows',
      defaultValue: 8,
      settings: {
        min: 1,
        max: 64
      },
      category: ['Grid'],
    })
    .addNumberInput({
      path: 'itemWidth',
      name: 'Item Width',
      description: 'Set the width of each item',
      defaultValue: 41,
      settings: {
        min: 25,
        max: 80
      },
      category: ['Grid'],
    })
    .addNumberInput({
      path: 'itemHeight',
      name: 'Item Height',
      description: 'Set the height of each item',
      defaultValue: 41,
      settings: {
        min: 25,
        max: 80
      },
      category: ['Grid'],
    })
    .addNumberInput({
      path: 'start_ip_segment_3',
      name: 'Start IP segment 3',
      description: '[ * . * . 1 . * ]',
      defaultValue: 1,
      category: ['IP-Range'],
    })
    .addNumberInput({
      path: 'start_ip_segment_4',
      name: 'Start IP segment 4',
      description: '[ * . * . * . 1 ]',
      defaultValue: 1,
      category: ['IP-Range'],
    })
    .addNumberInput({
      path: 'end_ip_segment_3',
      name: 'End IP segment 3',
      description: '[ * . * . 254 . * ]',
      defaultValue: 254,
      category: ['IP-Range'],
    })
    .addNumberInput({
      path: 'end_ip_segment_4',
      name: 'End IP segment 4',
      description: '[ * . * . * . 254 ]',
      defaultValue: 254,
      category: ['IP-Range'],
    })
    .addBooleanSwitch({
      path: 'showItemIP',
      name: 'Show IP segment in list',
      description: 'Toggle visibility of the IP segment for miners',
      defaultValue: true,
      category: ['Panel'],
    })
    .addBooleanSwitch({
      path: 'showPool',
      name: 'Show Pool Info',
      description: 'Toggle visibility of the pool information',
      defaultValue: true,
      category: ['Tooltip'],
    })
    .addBooleanSwitch({
      path: 'showHashrate',
      name: 'Show Hashrate',
      description: 'Toggle visibility of the hashrate',
      defaultValue: true,
      category: ['Tooltip'],
    })
    .addBooleanSwitch({
      path: 'showChipsBoards',
      name: 'Show Chips/Boards',
      description: 'Toggle visibility of the chips/boards section',
      defaultValue: true,
      category: ['Tooltip'],
    })
    .addBooleanSwitch({
      path: 'showTempFans',
      name: 'Show Temperature/Fans',
      description: 'Toggle visibility of the temperature/fan section',
      defaultValue: true,
      category: ['Tooltip'],
    })
    .addBooleanSwitch({
      path: 'showActionButtons',
      name: 'Show Curtail/Reboot Buttons',
      description: 'Toggle visibility of the curtail/reboot buttons',
      defaultValue: true,
      category: ['Panel'],
    });
});