// src/components/heatmodeBackground.ts

export const getBackgroundColor = (temp: number, hasFault: boolean, chipColor?: string, boardColor?: string, heatMapMode: boolean = false) => {
    if (heatMapMode) {
      if (temp < 15) return '#206ba6';
      if (temp < 25) return '#418fcf';
      if (temp < 35) return '#92b2a7';
      if (temp < 45) return '#c5ab6a';
      if (temp < 55) return '#ba744a';
      if (temp < 65) return '#ba4b4a';
      if (temp < 75) return '#b93130';
      if (temp < 85) return '#b01922';
      if (temp < 95) return '#d10808';
      return '#de0505';
    }
  
    return hasFault ? '#de0505' : chipColor || boardColor || '#ffffff';
  };
  