// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* tippy.css */
.tippy-box {
    padding: 10px;
    background: #eeeeeef2;
    color: #313131fa;
    border-radius: 10px;
    font-size: 0.75em;
    max-width: 200px;
    white-space: nowrap;
    transition: opacity 250ms ease-in-out;
    }
  
.tippy-arrow {
color: #eeeeeef0; /* Match the background color of the tooltip */
}

.tippy-content {
    position: relative;
    padding: 5px 5px;
    z-index: 1;
    margin: 2px 0px 4px;
    font-family: monospace;
    color: #3c3c3c;
}

.tippy-mono-small {
    font-size: 0.8em;
}

.tippy-head-span {
    font-size: 1.05em;
    font-weight: bold;
    display: block;
    border-bottom: 1px dashed #292929;
    padding: 0px 0px 11px 0px;
    margin-bottom: 13px;
    color: #50749b;
    text-align: center;
}

.tippy-value-span {
    color: #000000;
}

.tippy-value-span-with-border {
    border-bottom: 1px dashed #9f9f9f;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

.tippy-foot-span {
    font-size: 0.95em;
    font-weight: bold;
    display: block;
    border-top: 1px dashed #292929;
    padding: 14px 0px 0px 0px;
    margin-top: 12px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./styles/tippy.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,aAAa;IACb,qBAAqB;IACrB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,qCAAqC;IACrC;;AAEJ;AACA,gBAAgB,EAAE,8CAA8C;AAChE;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;IACV,mBAAmB;IACnB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,iCAAiC;IACjC,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,8BAA8B;IAC9B,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["/* tippy.css */\n.tippy-box {\n    padding: 10px;\n    background: #eeeeeef2;\n    color: #313131fa;\n    border-radius: 10px;\n    font-size: 0.75em;\n    max-width: 200px;\n    white-space: nowrap;\n    transition: opacity 250ms ease-in-out;\n    }\n  \n.tippy-arrow {\ncolor: #eeeeeef0; /* Match the background color of the tooltip */\n}\n\n.tippy-content {\n    position: relative;\n    padding: 5px 5px;\n    z-index: 1;\n    margin: 2px 0px 4px;\n    font-family: monospace;\n    color: #3c3c3c;\n}\n\n.tippy-mono-small {\n    font-size: 0.8em;\n}\n\n.tippy-head-span {\n    font-size: 1.05em;\n    font-weight: bold;\n    display: block;\n    border-bottom: 1px dashed #292929;\n    padding: 0px 0px 11px 0px;\n    margin-bottom: 13px;\n    color: #50749b;\n    text-align: center;\n}\n\n.tippy-value-span {\n    color: #000000;\n}\n\n.tippy-value-span-with-border {\n    border-bottom: 1px dashed #9f9f9f;\n    padding-bottom: 4px;\n    margin-bottom: 4px;\n}\n\n.tippy-foot-span {\n    font-size: 0.95em;\n    font-weight: bold;\n    display: block;\n    border-top: 1px dashed #292929;\n    padding: 14px 0px 0px 0px;\n    margin-top: 12px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
